
import { name, version, config as packageConfig } from "../../package.json";
import { app } from "@electron/remote";
import os from "os";
import { computed, defineComponent, ref } from "vue";
import { useAppSettings } from "@/plugins/app-settings/AppSettings";
import settings from "electron-settings";

/**
 * The Settings page for the electron app. Since this page uses electron specific apis, it
 * should only be available in the kiosk app.
 *
 * Settings are updated through electron-settings. The next time the app loads, the latest
 * settings will be pulled from electron-settings.
 */
export default defineComponent({
  name: "Settings",
  setup() {
    const appSettings = useAppSettings();
    const config = ref<Record<string, string | number>>({ ...appSettings });
    const pathNames = ["appData", "userData", "cache", "temp", "logs"] as const;

    return {
      name,
      version,
      config,
      os,
      paths: pathNames.map((name) => [name, app.getPath(name)]),
      ip: computed(() => {
        const ifaces = os.networkInterfaces();
        return Object.values(ifaces)
          .reduce(
            (a: Array<os.NetworkInterfaceInfo>, ifaces) =>
              a.concat(ifaces || []),
            []
          )
          .find((iface) => iface.family === "IPv4" && !iface.internal);
      }),
      save: () => settings.setSync("config", config.value),
      reset: () => {
        const value = { mode: "kiosk", ...packageConfig };
        settings.setSync("config", value);
        config.value = value;
      },
      exit: () => {
        if (app.isPackaged) {
          app.relaunch();
          app.exit();
        } else {
          window.location.replace("index.html");
        }
      },
    };
  },
});
